
import {defineComponent, ref, reactive, onMounted, watch, nextTick, inject} from "vue";
import { useRoute } from "vue-router";
import useFootPrint from "@/hooks/useFootPrint";
import api from "@/api/axios";
import wx from "weixin-sdk-js";
import axios from "@/api/axios";
import navigatorType from "@/utils/browser";
import SlotFooter from "@/components/common/SlotFooter.vue";
/*
import MenuTab from "@components/common/BnccMenuTab.vue";
*/
import Swiper from "swiper";
export default defineComponent({
  name: "bnccdetail",
  components: {
    SlotFooter,
    /*MenuTab,*/

  },
  setup() {
    onMounted(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
    onMounted(() => {
      nextTick(() => {
        const swiper = new Swiper(".swiper-container", {
          loop: false, // 循环模式选项
          autoplay: true,
          slidesPerView: 1,
          initialSlide: 0,
          loopAdditionalSlides: 3,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          // navigation: {
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          // },
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination',
          },
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },

          },
        });
      });
    });

    const shareTitle = ref('')
    const collectType = ref('')
    const route = useRoute();
    const id = ref(route.params.id);
    const classId = ref(route.params.classId);
    const tid = ref(0);
    const stock = ref(0);
    const anclassId = ref(0);
    const nclassId = ref(0);
    const bncc = ref({});
    const isFav = ref(false);
    const biaowuPriceOpen = ref();
    const discount = ref();
    const  productLotList = ref();
    const productLotMl = ref()
    const productLotnd = ref()
    const  basePrice = ref();
    const  tcList = ref();
    const lotid = ref();
    const tcid = ref();
    const lottype = ref();
    const tcindex = ref();
    const lotindex = ref();
    const lotMlIndex = ref()
    const lotndIndex = ref()
    const specChecked = ref(true);
    const mealChecked = ref(true);
    const productPrice = ref()
    const originalPrice = ref()
    const thirdFlag = ref(true)
    const ggSty = ref(false)
    const isLogin = ref()
    const comp = ref({});
    const { setFootItem } = useFootPrint();
    //add new 20230210
    const hsList = ref()
    const hsProductLot1 = ref()
    const hsProductLot2 = ref()
    const hsLot1Index = ref(0)
    const hsLot2Index = ref(0)
    const dnaList = ref()
    const dnaProductLot1 = ref()
    const dnaProductLot2 = ref()
    const dnaLot1Index = ref(0)
    const dnaLot2Index = ref(0)
    const ytList = ref()
    const ytProductLot1 = ref()
    const ytProductLot2 = ref()
    const ytLot1Index = ref(0)
    const ytLot2Index = ref(0)
    const dnaTqList = ref()
    const isApplyOpen = ref()
    const checkPrice =ref(0);
    const checkcellsPrice =ref(0);
    const checkvalue =ref();
    const isChecked =ref(false);
    const unfix  =ref(0);
    const special  =ref(0);

    //  展示用户信息
    const userInfo = inject("userInfo") as any;

    // const menu = ["标准菌株", "定量菌株","基因组DNA"];
    // const menu = ref();
    const menu = reactive<any>([]);
    const currentMenuItem = ref("标准菌株");

    const cellMenu = ["基本套餐","基因组DNA"];
    const currentCellMenu = ref("基本套餐");
    //标准菌株和定量菌株的切换

    function changeMenu(item: string) {

      isChecked.value = false;
      checkcellsPrice.value = 0;
      checkvalue.value = 0;
      currentMenuItem.value = item;
      if (item == '标准菌株') {
        //ao
        tcList.value && tcList.value.forEach((item: any) => {
          const dtxt = item.formName
          const darg = dtxt.substring(0, dtxt.indexOf('：')+1)//获取：前的文本
          const targ = dtxt.substring(dtxt.indexOf('+'),dtxt.lastIndexOf('+')+1)//获取第一个+号到最后一个+号之间的文本
          const lasttxt = dtxt.substring(dtxt.lastIndexOf('+')+1)//获取最后一个+号之后的文本
          //套餐三的文本进行处理
          if(item.lotid == 12){
            //如果选择斜面、菌液、平板
            if(productLotList.value[0].formName=="斜面"||productLotList.value[0].formName=="菌液"||productLotList.value[0].formName=="平板"){
              item.formName = darg + productLotList.value[0].formName + targ+"冻干粉"
            }else{
              item.formName = darg + productLotList.value[0].formName + targ+"活化培养物"
            }
          }else{
            item.formName = darg + productLotList.value[0].formName + targ+lasttxt
          }
        })

        productLotList.value.length>0 && productLotList.value.forEach((item: any) => {
          item.specChecked = false
        })
        productLotList.value[0].specChecked = true
        tcList.value.length && tcList.value.forEach((item: any) => {
          item.mealChecked = false
        })
        tcList.value[0].mealChecked = true
        lotid.value = productLotList.value[0].lotid;//表示冻干粉
        tcid.value= tcList.value[0].lotid;//表示套餐一：说明书
        lotindex.value=0;
        tcindex.value=0;
        // lotid.value = 3;//表示冻干粉
        // tcid.value= 10;//表示套餐一：说明书
        if (userInfo && userInfo.isDealer==2) {
          // productPrice.value = (Number(data.productLotList[0].outPrice)*(loginUser.discount?loginUser.discount:1)).toFixed(2)
          productPrice.value = Math.ceil(Number(((bncc.value as any).price * (discount.value ? discount.value : 1)).toFixed(2)))
          originalPrice.value = Number(((bncc.value as any).price));
        } else {
          productPrice.value = Number(((bncc.value as any).retailPrice));
        }
      } else if(item == '定量菌株'){
        productLotMl.value.length>0 && productLotMl.value.forEach((item: any) => {
          item.lotMlChecked = false;
        })
        productLotMl.value[0].lotMlChecked = true
        productLotnd.value.length>0 && productLotnd.value.forEach((item: any) => {

          item.lotNdChecked = false;
        })
        productLotnd.value[0].lotNdChecked = true
        lotid.value = productLotMl.value[0].lotid;//表示冻干粉
        tcid.value= productLotnd.value[0].lotid;//表示套餐一：说明书
        lotMlIndex.value = 0;
        lotndIndex.value = 0;
        // lotid.value = 13;//表示10mL
        // tcid.value= 16;//表示10^6以下



        if (userInfo && userInfo.isDealer==2) {
          const totalPrice = Number((bncc.value as any).price) + productLotMl.value[0].addPrice + productLotnd.value[0].addPrice;
          productPrice.value = Math.ceil(Number((totalPrice * (discount.value ? discount.value : 1)).toFixed(2)));
          originalPrice.value = Math.ceil(Number(totalPrice))
        } else {
          const totalPrice = Number((bncc.value as any).retailPrice) + productLotMl.value[0].addPrice + productLotnd.value[0].addPrice;
          productPrice.value = Math.ceil(Number(totalPrice));
        }
      } else if(item == '基因组DNA'){
        lotid.value = 23;//规格值默认为0
        tcid.value = 0;//规格值默认为0

        if (userInfo && userInfo.isDealer==2) {
          const totalPrice = Number((bncc.value as any).price)+200
          // productPrice.value = (Number(data.productLotList[0].outPrice)*(loginUser.discount?loginUser.discount:1)).toFixed(2)
          productPrice.value = Math.ceil(Number(( totalPrice* (discount.value ? discount.value : 1)).toFixed(2)))
          originalPrice.value = Number((totalPrice));
        } else {
          const totalPrice = Number((bncc.value as any).retailPrice)+200
          productPrice.value = Number((totalPrice));
        }
      } else if(item == '核酸参考品'){
        if(hsList.value&& hsList.value.length>0){
          lotid.value = 0;
          tcid.value = 0;
        }else{
          lotid.value = hsProductLot1.value[0].lotid;
          tcid.value= hsProductLot2.value[0].lotid;
          hsLot1Index.value=0;
          hsLot2Index.value=0;

          hsProductLot1.value && hsProductLot1.value.forEach((item: any) => {
            item.lotMlChecked = false;
          })
          hsProductLot1.value[0].lotMlChecked = true
          hsProductLot2.value && hsProductLot2.value.forEach((item: any) => {
            item.lotNdChecked = false;
          })
          hsProductLot2.value[0].lotNdChecked = true

          const totalPrice = Number((basePrice.value*hsProductLot2.value[0].times).toFixed(2))+Number(hsProductLot2.value[0].addPrice);
          if (userInfo && userInfo.isDealer==2) {
            productPrice.value = Math.ceil(Number((totalPrice * (discount.value ? discount.value : 1)).toFixed(2)));
            originalPrice.value = Math.ceil(Number(totalPrice))
          } else {
            productPrice.value = Math.ceil(Number(totalPrice));
          }
        }
      }else if(item == '核酸标准品'){
        if(dnaList.value&& dnaList.value.length>0){
          lotid.value = 0;
          tcid.value = 0;
        }else{
          lotid.value = dnaProductLot1.value[0].lotid;
          tcid.value= dnaProductLot2.value[0].lotid;
          dnaLot1Index.value=0;
          dnaLot2Index.value=0;

          dnaProductLot1.value && dnaProductLot1.value.forEach((item: any) => {
            item.lotMlChecked = false;
          })
          dnaProductLot1.value[0].lotMlChecked = true
          dnaProductLot2.value && dnaProductLot2.value.forEach((item: any) => {
            item.lotNdChecked = false;
          })
          dnaProductLot2.value[0].lotNdChecked = true
          const totalPrice = Number((basePrice.value*dnaProductLot2.value[0].times).toFixed(2))+Number(dnaProductLot2.value[0].addPrice);
          if (userInfo && userInfo.isDealer==2) {
            productPrice.value = Math.ceil(Number((totalPrice * (discount.value ? discount.value : 1)).toFixed(2)));
            originalPrice.value = Math.ceil(Number(totalPrice))
          } else {
            productPrice.value = Math.ceil(Number(totalPrice));
          }
        }
      } else if(item == '液体室内质控品'){
        if(ytList.value&& ytList.value.length>0){
          lotid.value = 0;
          tcid.value = 0;
        }else{
          lotid.value = ytProductLot1.value[0].lotid;
          tcid.value= ytProductLot2.value[0].lotid;
          ytLot1Index.value=0;
          ytLot2Index.value=0;

          ytProductLot1.value && ytProductLot1.value.forEach((item: any) => {
            item.lotMlChecked = false;
          })
          ytProductLot1.value[0].lotMlChecked = true
          ytProductLot2.value && ytProductLot2.value.forEach((item: any) => {
            item.lotNdChecked = false;
          })
          ytProductLot2.value[0].lotNdChecked = true

          // const totalPrice = Number((basePrice.value*ytProductLot2.value[0].times).toFixed(2))+Number(ytProductLot2.value[0].addPrice);
          const totalPrice = Number(ytProductLot2.value[0].addPrice);
          if (userInfo && userInfo.isDealer==2) {
            productPrice.value = Math.ceil(Number((totalPrice * (discount.value ? discount.value : 1)).toFixed(2)));
            originalPrice.value = Math.ceil(Number(totalPrice))
          } else {
            productPrice.value = Math.ceil(Number(totalPrice));
          }
        }
      }
    }
    //基本套餐和DNA提取的切换
    function changeCellMenu(item: string) {
      currentCellMenu.value = item;
      if (item == '基本套餐') {
        tcList.value.length && tcList.value.forEach((item: any) => {
          item.mealChecked = false
        })
        //套餐一被默认选中
        tcList.value[0].mealChecked = true
        tcid.value= tcList.value[0].lotid;//表示套餐一
        lotid.value = 0;//规格值默认为0

        if (userInfo.isDealer==2) {
          productPrice.value = Math.ceil(Number(((bncc.value as any).price * (discount.value ? discount.value : 1)).toFixed(2)))
          originalPrice.value = Number(((bncc.value as any).price));//原价
        } else {
          productPrice.value = Number(((bncc.value as any).retailPrice));
        }
      } else if(item == '基因组DNA'){
        lotid.value = 24;//规格值默认为0
        tcid.value = 0;//规格值默认为0

        if (userInfo.isDealer==2) {
          const totalPrice =Number((bncc.value as any).price)+200;
          productPrice.value = Math.ceil(Number((totalPrice * (discount.value ? discount.value : 1)).toFixed(2)));
          originalPrice.value = Math.ceil(Number((totalPrice)));
        } else {
          const totalPrice =Number((bncc.value as any).retailPrice)+200;
          productPrice.value = Math.ceil(Number(totalPrice));
        }
      }
    }

    function getData(id: any) {

      api
          .get("/M/Product/BNCCDetail/" + id)
          .then((res): void => {
            const data = res.data.obj;
            isApplyOpen.value = data.isApplyOpen;
            if(!isApplyOpen.value){
              isApplyOpen.value =0;
            }
            biaowuPriceOpen.value = data.biaowuPriceOpen
            discount.value = data.discount
            bncc.value = data.bncc;
            isFav.value = data.isFav;
            anclassId.value = data.bncc.anclassId;
            nclassId.value = data.bncc.nclassId;
            if(userInfo.isDealer==2){
              basePrice.value = data.bncc.price;
            }else{
              basePrice.value = data.bncc.retailPrice;
            }

            collectType.value = data.collectType.toString()
            shareTitle.value = data.title
            document.title = data.title + '-北纳生物'
            route.meta.title = data.title
            route.meta.content.description = data.description;
            lotindex.value =0;
            tcindex.value =0;
            lotMlIndex.value = 0
            lotndIndex.value = 0
            tid.value = data.bncc.anclassId
            const isPc = navigatorType();
            if (isPc) {
              window.location.href = `https://www.bncc.com/pro/p${data.bncc.anclassId}/${data.bncc.nclassId}/p_${data.bncc.id}.html`;
            }
            stock.value = Number(data.bncc.productStock)
            special.value=Number(data.bncc.special)
            if(data.bncc.unfix ==1){
              // menu.push("标准菌株");
              menu.value =["标准菌株","基因组DNA","核酸参考品","核酸标准品","液体室内质控品"];
              // console.log(menu.value,'menu');
            }else{
              // menu.push("标准菌株");
              menu.value =["标准菌株", "定量菌株","基因组DNA","核酸参考品","核酸标准品","液体室内质控品"];
            }
            const lotList: any[] = []
            if(anclassId.value == 2 && data.bncc.nclassId == 8){
              const productFormat = data.bncc.productFormat.split('；')
              data.productLotList && data.productLotList.forEach((item: any) => {
                productFormat.forEach((ele: any) => {
                  if (item.formName == ele) {
                    lotList.push(item)
                  }
                });
                item.specChecked = false
              })
            }else if(anclassId.value == 1){//细胞
              // data.productLotList && data.productLotList.forEach((item: any) => {
              //   lotList.push(item)
              //   item.specChecked = false
              // })
            }else if((anclassId.value == 5 && data.bncc.nclassId == 25) ||(anclassId.value == 10 && data.bncc.nclassId == 37) ){//感受态细胞
              const productFormat = data.bncc.productFormat.split('；')
              data.productLotList && data.productLotList.forEach((item: any) => {
                productFormat.forEach((ele: any) => {
                  if (item.formName == ele) {
                    lotList.push(item)
                  }
                });
                item.specChecked = false
              })
            }else{
              lotid.value = 0;
              tcid.value = 0;
            }
            if (lotList.length>0) {
              lotList[0].specChecked = true;
              lotid.value = lotList[0].lotid;

            }
            productLotList.value = lotList;

            data.tcList && data.tcList.forEach((item: any) => {
              if(data.bncc.speciesGenus && data.bncc.speciesGenus.indexOf("bacteriophage") !=-1){
                //删除集合中下标为2的对象，2数组下标，1表示删除
                data.tcList.splice(2,1)
              }
              item.mealChecked = false
            })
            if (data.tcList.length>0) {
              data.tcList[0].mealChecked = true;
              tcid.value = data.tcList[0].lotid
            }
            tcList.value = data.tcList;
            data.productLotMl && data.productLotMl.forEach((item: any) => {
              item.lotMlChecked = false
            })
            if (data.productLotMl.length>0) {
              data.productLotMl[0].lotMlChecked = true;
            }
            productLotMl.value = data.productLotMl

            data.productLotnd && data.productLotnd.forEach((item: any,index: any) => {
              if(item.lotid == 40 && data.bncc.unfix ==2){
                data.productLotnd.splice(index,2);
              }
              item.lotNdChecked = false
            })
            if (data.productLotnd.length>0) {
              data.productLotnd[0].lotNdChecked = true;
            }
            productLotnd.value = data.productLotnd
            unfix.value = data.bncc.unfix;
            //add new 20230210
            if(anclassId.value == 2 && data.bncc.nclassId == 8){
                // ONE
              if(data.hsList&& data.hsList.length>0){
                hsList.value = data.hsList;
              }else{
                data.hsProductLot1 && data.hsProductLot1.forEach((item: any) => {
                  item.lotMlChecked = false
                })
                if (data.hsProductLot1.length>0) {
                  data.hsProductLot1[0].lotMlChecked = true;
                }
                hsProductLot1.value = data.hsProductLot1;
                data.hsProductLot2 && data.hsProductLot2.forEach((item: any,index: any) => {
                  item.lotNdChecked = false
                })
                if (data.hsProductLot2.length>0) {
                  data.hsProductLot2[0].lotNdChecked = true;
                }
                hsProductLot2.value = data.hsProductLot2
              }
                // TWO
              if(data.dnaList&& data.dnaList.length>0){
                dnaList.value = data.dnaList;
              }else{
                data.dnaProductLot1 && data.dnaProductLot1.forEach((item: any) => {
                  item.lotMlChecked = false
                })
                if (data.dnaProductLot1.length>0) {
                  data.dnaProductLot1[0].lotMlChecked = true;
                }
                dnaProductLot1.value = data.dnaProductLot1;
                data.dnaProductLot2 && data.dnaProductLot2.forEach((item: any,index: any) => {
                  item.lotNdChecked = false
                })
                if (data.dnaProductLot2.length>0) {
                  data.dnaProductLot2[0].lotNdChecked = true;
                }
                dnaProductLot2.value = data.dnaProductLot2
              }
                // THREE
              if(data.ytList&& data.ytList.length>0){
                ytList.value = data.ytList;
              }else{
                data.ytProductLot1 && data.ytProductLot1.forEach((item: any) => {
                  item.lotMlChecked = false
                })
                if (data.ytProductLot1.length>0) {
                  data.ytProductLot1[0].lotMlChecked = true;
                }
                ytProductLot1.value = data.ytProductLot1;
                data.ytProductLot2 && data.ytProductLot2.forEach((item: any,index: any) => {
                  item.lotNdChecked = false
                })
                if (data.ytProductLot2.length>0) {
                  data.ytProductLot2[0].lotNdChecked = true;
                }
                ytProductLot2.value = data.ytProductLot2
              }
              // Four
              if(data.dnaTqList&& data.dnaTqList.length>0){
                dnaTqList.value = data.dnaTqList;
              }

            }



            // 判断价格
            // 判断是否登录
            const loginUser = data.loginUser;
            isLogin.value = data.loginUser
            if (userInfo.isDealer==2) {
              productPrice.value = Math.ceil(Number(
                  (data.bncc.price * (discount.value ? discount.value : 1)).toFixed(2)
              ))
              originalPrice.value = data.bncc.price
            } else {
              productPrice.value = Math.ceil(Number(
                      (data.bncc.retailPrice * (discount.value ? discount.value : 1)).toFixed(2)
              ))
              originalPrice.value = data.bncc.retailPrice
            }


            setFootItem({
              type: "bncc",
              img: data.bncc.switchBnccUrl,
              title: data.bncc.strainDesignations,
              info: "BNCC" + data.bncc.id,
              id: data.bncc.id,
              brand: data.bncc.speciesGenus,
              summary: data.bncc.bnccid,
              url: {name: "bnccdetail", query: {id: route.params.id, classId: route.params.id}},
            });
          })
          .catch((err) => {
            console.log(err);
          });
    }
    getData(id.value);

    //感受态细胞的规格切换事件
    function clickZlSpec(item: any, index: any) {
      lotindex.value =index;
      productLotList.value.forEach((item: any) => {
        item.specChecked = false;
      })
      productLotList.value[index].specChecked = true;
      lotid.value = productLotList.value[index].lotid;
      specChecked.value = false;
      // 价格信息获取  判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1 ) {//经销商有折扣
          //第一个规格被选中时，默认没有附加价格
          if(index==0){
            productPrice.value = Math.ceil(Number((basePrice.value*discount.value+0).toFixed(2)))
            originalPrice.value = Math.ceil(Number(basePrice.value+0))
          }else{
            productPrice.value = Math.ceil(Number(((basePrice.value+productLotList.value[index].addPrice)*discount.value).toFixed(2)))
            originalPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[index].addPrice))
          }
        } else {
          //第一个规格被选中时，默认没有附加价格
          if(index==0){
            productPrice.value = Math.ceil(Number(basePrice.value+0))
          }else{
            productPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[index].addPrice))
          }
        }
      }
    }

    //噬菌体的规格切换事件
    function clickBacte(item: any, index: any) {
      lotindex.value =index;
      productLotList.value.forEach((item: any) => {
        item.specChecked = false;
      })
      productLotList.value[index].specChecked = true;
      lotid.value = productLotList.value[index].lotid;
      specChecked.value = false;
      // 价格信息获取  判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {//经销商有折扣
          //第一个规格被选中时，默认没有附加价格
          if(index==0){
            productPrice.value = Math.ceil(Number((basePrice.value*discount.value+0).toFixed(2)))
            originalPrice.value = Math.ceil(Number(basePrice.value+0))
          }else{
            productPrice.value = Math.ceil(Number(((basePrice.value+productLotList.value[index].addPrice)*discount.value).toFixed(2)))
            originalPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[index].addPrice))
          }
        } else {
          //第一个规格被选中时，默认没有附加价格
          if(index==0){
            productPrice.value = Math.ceil(Number(basePrice.value+0))
          }else{
            productPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[index].addPrice))
          }
        }
      }
    }
    //标准菌株勾选延保服务
    function extended(e: any){
      isChecked.value= ! isChecked.value;
      // const checked = e.target.checked;
      if(isChecked.value){
        checkPrice.value =200;
        checkvalue.value =  1;
        console.log(isChecked.value,66666666666)
        productPrice.value = Math.ceil(Number(((basePrice.value+productLotList.value[lotindex.value].addPrice+ tcList.value[tcindex.value].addPrice+checkPrice.value)*discount.value).toFixed(2)));
        originalPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[lotindex.value].addPrice+ tcList.value[tcindex.value].addPrice+checkPrice.value))
      }else{
        checkPrice.value =0;
        checkvalue.value = 0;
        console.log(isChecked.value,888888888)
        productPrice.value = Math.ceil(Number(((basePrice.value+productLotList.value[lotindex.value].addPrice+ tcList.value[tcindex.value].addPrice)*discount.value).toFixed(2)));
        originalPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[lotindex.value].addPrice+ tcList.value[tcindex.value].addPrice))
      }


    }
    //细胞菌株勾选延保服务
    function cellscheck(e: any){
      isChecked.value= ! isChecked.value;
      if(isChecked.value){
        checkcellsPrice.value =300;
        checkvalue.value =  1;
        productPrice.value = Math.ceil(Number(((basePrice.value+tcList.value[tcindex.value].addPrice+checkcellsPrice.value)*discount.value).toFixed(2)))
        originalPrice.value = Math.ceil(Number(basePrice.value+tcList.value[tcindex.value].addPrice+checkcellsPrice.value))
      }else{
        checkcellsPrice.value =0;
        checkvalue.value = 0;
        productPrice.value = Math.ceil(Number(((basePrice.value+tcList.value[tcindex.value].addPrice)*discount.value).toFixed(2)))
        originalPrice.value = Math.ceil(Number(basePrice.value+tcList.value[tcindex.value].addPrice))
      }


    }
    //菌种的规格切换事件
    function clickBnccSpec(item: any, index: any) {
      console.log("guige",checkPrice.value)
      let ztxt = ''
      const itemName = item.formName
      tcList.value && tcList.value.forEach((item: any) => {
        const dtxt = item.formName
        const darg = dtxt.substring(0, dtxt.indexOf('：')+1)//获取：前的文本
        const targ = dtxt.substring(dtxt.indexOf('+'),dtxt.lastIndexOf('+')+1)//获取第一个+号到最后一个+号之间的文本
        const lasttxt = dtxt.substring(dtxt.lastIndexOf('+')+1)//获取最后一个+号之后的文本
        //套餐三的文本进行处理
        if(item.lotid == 12){
          //如果选择斜面、菌液、平板
          if(itemName=="斜面"||itemName=="菌液"||itemName=="平板"){
            ztxt = darg + itemName + targ+"冻干粉"
          }else{
            ztxt = darg + itemName + targ+"活化培养物"
          }
        }else{
          ztxt = darg + itemName + targ+lasttxt
        }
        item.formName = ztxt
      })

      lotindex.value =index;
      productLotList.value.forEach((item: any) => {
        item.specChecked = false;
      })
      productLotList.value[index].specChecked = true;
      lotid.value = productLotList.value[index].lotid;
      specChecked.value = false;



      // 价格信息获取  判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {//经销商有折扣
          //第一个规格被选中时，默认没有附加价格
          if(index==0){
            productPrice.value = Math.ceil(Number(((basePrice.value+0+ tcList.value[tcindex.value].addPrice+checkPrice.value)*discount.value).toFixed(2)));
            originalPrice.value = Math.ceil(Number(basePrice.value+0+ tcList.value[tcindex.value].addPrice+checkPrice.value))
          }else{
            productPrice.value = Math.ceil(Number(((basePrice.value+productLotList.value[index].addPrice+ tcList.value[tcindex.value].addPrice+checkPrice.value)*discount.value).toFixed(2)))
            originalPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[index].addPrice+ tcList.value[tcindex.value].addPrice+checkPrice.value))
          }
        } else {
          //第一个规格被选中时，默认没有附加价格
          if(index==0){
            productPrice.value = Math.ceil(Number(basePrice.value+checkPrice.value+ tcList.value[tcindex.value].addPrice))
          }else{
            productPrice.value = Math.ceil(Number(basePrice.value+productLotList.value[index].addPrice+ tcList.value[tcindex.value].addPrice+checkPrice.value))
          }
        }
      }
    }
    //菌种的套餐切换事件
    function clickBnccMeal(item: any, index: any) {
      console.log("taocan",checkPrice.value)
      ggSty.value = false
      tcindex.value =index;
      tcList.value.forEach((item: any) => {
        item.mealChecked = false;
      })
      tcList.value[index].mealChecked = true;
      tcid.value = tcList.value[index].lotid;

      mealChecked.value = false;

      // 判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {
          //第一个规格被选中时，默认没有附加价格
          if(lotindex.value==0){
            productPrice.value = Math.ceil(Number(((basePrice.value+tcList.value[index].addPrice+checkPrice.value)*discount.value).toFixed(2)))
            originalPrice.value = Math.ceil(Number(basePrice.value+tcList.value[index].addPrice+checkPrice.value))
          }else{
            productPrice.value = Math.ceil(Number(((basePrice.value+tcList.value[index].addPrice+productLotList.value[lotindex.value].addPrice)*discount.value).toFixed(2)))
            originalPrice.value = Math.ceil(Number(basePrice.value+tcList.value[index].addPrice+ productLotList.value[lotindex.value].addPrice))
          }
        } else {
          //第一个规格被选中时，默认没有附加价格
          if(lotindex.value==0){
            productPrice.value = Math.ceil(Number(basePrice.value+tcList.value[index].addPrice+checkPrice.value))
          }else{
            productPrice.value = Math.ceil(Number(basePrice.value+tcList.value[index].addPrice+ productLotList.value[lotindex.value].addPrice))
          }
        }
      }
    }

    //细胞的套餐切换事件
    function clickCellMeal(item: any, index: any) {
      ggSty.value = false
      tcindex.value =index;
      tcList.value.forEach((item: any) => {
        item.mealChecked = false;
      })
      tcList.value[index].mealChecked = true;
      tcid.value = tcList.value[index].lotid;
      mealChecked.value = false;
      lotid.value = 0;
      // 判断是否登录
      if (userInfo) {
        if (userInfo.cellDiscount < 1) {
          productPrice.value = Math.ceil(Number(((basePrice.value+tcList.value[index].addPrice+checkcellsPrice.value)*discount.value).toFixed(2)))
          originalPrice.value = Math.ceil(Number(basePrice.value+tcList.value[index].addPrice+checkcellsPrice.value))
        } else {
          productPrice.value = Math.ceil(Number(basePrice.value+tcList.value[index].addPrice+checkcellsPrice.value))
        }
      }
    }
    function clickLotMl(item: any, index: any) {
      lotMlIndex.value = index
      productLotMl.value.forEach((item: any) => {
        item.lotMlChecked = false;
      })
      item.lotMlChecked = true
      lotid.value = productLotMl.value[index].lotid;
      let times = 1;
      if(unfix.value == 2){
        if(lotid.value == 13){
          times = productLotnd.value[lotndIndex.value].times;
        }else{
          times = productLotnd.value[lotndIndex.value].timesTwo;
        }
      }
      let totalPrice: number;
      if(lotid.value == 13){
        totalPrice = Number(basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPrice * times);
      }else{
        totalPrice = Number(basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPriceTwo * times);
      }
      // 判断是否登录
      if (userInfo) {
        // if (userInfo.bnccDiscount < 1) {
        //   if(lotid.value == 13){
        //     productPrice.value = Math.ceil(Number(((basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPrice)*discount.value).toFixed(2)))
        //     originalPrice.value = Math.ceil(Number(basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPrice))
        //   }else{
        //     productPrice.value = Math.ceil(Number(((basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPriceTwo)*discount.value).toFixed(2)))
        //     originalPrice.value = Math.ceil(Number(basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPriceTwo))
        //   }
        //
        // } else {
        //   if(lotid.value == 13){
        //     productPrice.value = Math.ceil(Number(basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPrice))
        //   }else{
        //     productPrice.value = Math.ceil(Number(basePrice.value+productLotMl.value[index].addPrice+ productLotnd.value[lotndIndex.value].addPriceTwo))
        //   }
        //
        // }
        if (discount.value < 1) {
          productPrice.value = Math.ceil(Number((totalPrice*discount.value).toFixed(2)));
          originalPrice.value = Math.ceil(totalPrice);
        } else {
          productPrice.value = Math.ceil(totalPrice);
        }
      }

    }
    function clickLotNd(item: any, index: any) {
      lotndIndex.value = index
      productLotnd.value.forEach((item: any) => {
        item.lotNdChecked = false;
      })
      item.lotNdChecked = true
      lottype.value =productLotMl.value[lotMlIndex.value].lotid;
      tcid.value = productLotnd.value[index].lotid;
      productPrice.value += item.addPrice
      let times = 1;
      if(unfix.value == 2){
        if(lottype.value == 13){

          times = productLotnd.value[index].times;
          console.log('times1',times)
        }else{
          times = productLotnd.value[index].timesTwo;
          console.log('times2',times)
        }
      }
      let totalPrice: number;
      if(lottype.value == 13){
        totalPrice = Number(basePrice.value+productLotnd.value[index].addPrice * times+ productLotMl.value[lotMlIndex.value].addPrice);
      }else{
        totalPrice = Number(basePrice.value+productLotnd.value[index].addPriceTwo * times+ productLotMl.value[lotMlIndex.value].addPrice);
      }
      // 判断是否登录
      if (userInfo) {
        // if (userInfo.bnccDiscount < 1) {
        //   if(lottype.value == 13){
        //     productPrice.value = Math.ceil(Number(((basePrice.value+productLotnd.value[index].addPrice+ productLotMl.value[lotMlIndex.value].addPrice)*discount.value).toFixed(2)))
        //     originalPrice.value = Math.ceil(Number(basePrice.value+productLotnd.value[index].addPrice+ productLotMl.value[lotMlIndex.value].addPrice))
        //   }else{
        //     productPrice.value = Math.ceil(Number(((basePrice.value+productLotnd.value[index].addPriceTwo+ productLotMl.value[lotMlIndex.value].addPrice)*discount.value).toFixed(2)))
        //     originalPrice.value = Math.ceil(Number(basePrice.value+productLotnd.value[index].addPriceTwo+ productLotMl.value[lotMlIndex.value].addPrice))
        //   }
        //
        // } else {
        //   if(lottype.value == 13){
        //     productPrice.value = Math.ceil(Number(basePrice.value+productLotnd.value[index].addPrice+ productLotMl.value[lotMlIndex.value].addPrice))
        //   }else{
        //     productPrice.value = Math.ceil(Number(basePrice.value+productLotnd.value[index].addPriceTwo+ productLotMl.value[lotMlIndex.value].addPrice))
        //   }
        // }
        if (discount.value < 1) {
          productPrice.value = Math.ceil(Number((totalPrice*discount.value).toFixed(2)));
          originalPrice.value = Math.ceil(totalPrice);
        } else {
          productPrice.value =Math.ceil(totalPrice);
        }
      }
    }

    /**
     * 核酸参考品切换-规格
     * @param item
     * @param index
     */
    function clickLotHSGe(item: any, index: any) {
      hsLot1Index.value = index
      hsProductLot1.value.forEach((item: any) => {
        item.lotMlChecked = false;
      })
      item.lotMlChecked = true
      lotid.value =hsProductLot1.value[index].lotid;
      let totalPrice;
      if(lotid.value == 30){//单管
        totalPrice = Number((basePrice.value*hsProductLot2.value[hsLot2Index.value].times).toFixed(2))+Number(hsProductLot2.value[hsLot2Index.value].addPrice);
      }else{
        totalPrice = Number((basePrice.value*hsProductLot2.value[hsLot2Index.value].timesTwo).toFixed(2))+Number(hsProductLot2.value[hsLot2Index.value].addPriceTwo);
      }
      // 判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {
            productPrice.value =Math.ceil(Number((totalPrice * discount.value).toFixed(2)));
            originalPrice.value = Math.ceil(Number(totalPrice));
        } else {
          productPrice.value =Math.ceil(Number(totalPrice));
        }
      }
    }
    /**
     * 核酸参考品切换-浓度
     * @param item
     * @param index
     */
    function clickLotHSNd(item: any, index: any) {
      hsLot2Index.value = index
      hsProductLot2.value.forEach((item: any) => {
        item.lotNdChecked = false;
      })
      item.lotNdChecked = true
      tcid.value = hsProductLot2.value[index].lotid;
      const currentGeLotId = hsProductLot1.value[hsLot1Index.value].lotid;
      let totalPrice: number;
      if(currentGeLotId == 30){//单管
        totalPrice = Number((basePrice.value*hsProductLot2.value[index].times).toFixed(2))+Number(hsProductLot2.value[index].addPrice);
      }else{
        totalPrice = Number((basePrice.value*hsProductLot2.value[index].timesTwo).toFixed(2))+Number(hsProductLot2.value[index].addPriceTwo);
      }
      // 判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {
          productPrice.value =Math.ceil(Number((totalPrice * discount.value).toFixed(2)));
          originalPrice.value = Math.ceil(Number(totalPrice));
        } else {
          productPrice.value =Math.ceil(Number(totalPrice));
        }
      }
    }
    /**
     * 核酸标准品-规格
     * @param item
     * @param index
     */
    function clickLotDNAGe(item: any, index: any) {
      dnaLot1Index.value = index
      dnaProductLot1.value.forEach((item: any) => {
        item.lotMlChecked = false;
      })
      item.lotMlChecked = true
      lotid.value =dnaProductLot1.value[index].lotid;
      let totalPrice: number;
      if(lotid.value == 35){//单管
        totalPrice = Number((basePrice.value*dnaProductLot2.value[dnaLot2Index.value].times).toFixed(2))+Number(dnaProductLot2.value[dnaLot2Index.value].addPrice);
      }else{
        totalPrice = Number((basePrice.value*dnaProductLot2.value[dnaLot2Index.value].timesTwo).toFixed(2))+Number(dnaProductLot2.value[dnaLot2Index.value].addPriceTwo);
      }
      // 判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {
            productPrice.value =Math.ceil(Number((totalPrice * discount.value).toFixed(2)));
            originalPrice.value = Math.ceil(Number(totalPrice));
        } else {
          productPrice.value =Math.ceil(Number(totalPrice));
        }
      }
    }
    /**
     * 核酸标准品-浓度
     * @param item
     * @param index
     */
    function clickLotDNANd(item: any, index: any) {
      dnaLot2Index.value = index
      dnaProductLot2.value.forEach((item: any) => {
        item.lotNdChecked = false;
      })
      item.lotNdChecked = true;
      tcid.value = dnaProductLot2.value[index].lotid;
      const currentGeLotId = dnaProductLot1.value[dnaLot1Index.value].lotid;
      let totalPrice: number;
      if(currentGeLotId == 35){//单管
        totalPrice = Number((basePrice.value*dnaProductLot2.value[index].times).toFixed(2))+Number(dnaProductLot2.value[index].addPrice);
      }else{
        totalPrice = Number((basePrice.value*dnaProductLot2.value[index].timesTwo).toFixed(2))+Number(dnaProductLot2.value[index].addPriceTwo);
      }
      // 判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {
          productPrice.value =Math.ceil(Number((totalPrice * discount.value).toFixed(2)));
          originalPrice.value = Math.ceil(Number(totalPrice));
        } else {
          productPrice.value =Math.ceil(Number(totalPrice));
        }
      }
    }

    /**
     * 液体室内质控品-规格
     * @param item
     * @param index
     */
    function clickLotYTGe(item: any, index: any) {
      ytLot1Index.value = index
      ytProductLot1.value.forEach((item: any) => {
        item.lotMlChecked = false;
      })
      item.lotMlChecked = true
      lotid.value =ytProductLot1.value[index].lotid;
      let totalPrice: number;
      if(lotid.value == 25){//单管
        // totalPrice = Number((basePrice.value*ytProductLot2.value[ytLot2Index.value].times).toFixed(2))+Number(ytProductLot2.value[ytLot2Index.value].addPrice);
        totalPrice =Number(ytProductLot2.value[ytLot2Index.value].addPrice);
      }else{
        // totalPrice = Number((basePrice.value*ytProductLot2.value[ytLot2Index.value].timesTwo).toFixed(2))+Number(ytProductLot2.value[ytLot2Index.value].addPriceTwo);
        totalPrice =Number(ytProductLot2.value[ytLot2Index.value].addPriceTwo);
      }
      // console.log("basePrice.value",basePrice.value);
      // console.log("ytProductLot2.value[ytLot2Index.value].times",ytProductLot2.value[ytLot2Index.value].times);
      // console.log("ytProductLot2.value[ytLot2Index.value].addPrice",ytProductLot2.value[ytLot2Index.value].addPrice);
      // console.log("totalPrice",totalPrice);

      // 判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount <1) {
            productPrice.value =Math.ceil(Number((totalPrice * discount.value).toFixed(2)));
            originalPrice.value = Math.ceil(Number(totalPrice));
        } else {
          productPrice.value =Math.ceil(Number(totalPrice));
        }
      }
    }
    /**
     * 液体室内质控品-浓度
     * @param item
     * @param index
     */
    function clickLotYTNd(item: any, index: any) {
      ytLot2Index.value = index
      ytProductLot2.value.forEach((item: any) => {
        item.lotNdChecked = false;
      })
      item.lotNdChecked = true;
      tcid.value = ytProductLot2.value[index].lotid;
      const currentGeLotId = ytProductLot1.value[ytLot1Index.value].lotid;
      let totalPrice: number;
      if(currentGeLotId == 25){//单管
        // totalPrice = Number((basePrice.value*ytProductLot2.value[index].times).toFixed(2))+Number(ytProductLot2.value[index].addPrice);
        totalPrice =Number(ytProductLot2.value[index].addPrice);
      }else{
        // totalPrice = Number((basePrice.value*ytProductLot2.value[index].timesTwo).toFixed(2))+Number(ytProductLot2.value[index].addPriceTwo);
        totalPrice =Number(ytProductLot2.value[index].addPriceTwo);
      }
      // console.log("currentGeLotId",currentGeLotId);
      // console.log("basePrice.value",basePrice.value);
      // console.log("ytProductLot2.value[ytLot2Index.value].times",ytProductLot2.value[ytLot2Index.value].times);
      // console.log("ytProductLot2.value[ytLot2Index.value].addPrice",ytProductLot2.value[ytLot2Index.value].addPrice);
      // console.log("totalPrice",totalPrice);
      // 判断是否登录
      if (userInfo) {
        if (userInfo.bnccDiscount < 1) {
          productPrice.value =Math.ceil(Number((totalPrice * discount.value).toFixed(2)));
          originalPrice.value = Math.ceil(Number(totalPrice));
        } else {
          productPrice.value =Math.ceil(Number(totalPrice));
        }
      }
    }
    /**
     * 售卖体系Tab切换-屏幕自动滑动
     * @param item
     * @param index
     */
    function  scrollLeftTo(item: any,index: any) {
      changeMenu(item);
      const ref = `tab${index}Ref`;
      // 获取myTabRef的DOM元素，即类名为my-tab的标签
      const nav = this.$refs.myTabRef;
      // 获取当前点击的某一个tab的的DOM元素，即类名为my-tab-item的标签
      const title = this.$refs[ref];
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2;
      nav.scrollLeft = to;
    }
    //监听当前页面的跳转
    watch(
          [
            () => {
              return route.query;
            },
            () => {
              return route.params;
            },
          ],
          (to, from) => {
            if (to[1].id && !from[1].valid && !from[1].validate) {
              getData(to[1].id as string);
            }
          }
    );


    watch(
        () => {
          return shareTitle.value
        },
        (to, from) => {
          const isweixin = ref(false);
          const ua = navigator.userAgent.toLowerCase();
          const reg = /MicroMessenger/i;
          isweixin.value = reg.test(ua);
          if (isweixin.value) {
            nextTick(() => {
              // const imgUrl = "http://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/logo20200923.svg";
              const link = window.location.href;
              const title = document.title;
              const desc = (document.querySelectorAll(
                  "meta[name=description]"
              )[0] as any).content;

              const formData = new FormData();
              formData.append("url", link);
              axios.post("/M/Server/getweixin", formData).then((res: any) => {
                const data = res.data;
                wx.config({
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: data.appid, // 必填，公众号的唯一标识
                  timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                  nonceStr: data.nonceStr, // 必填，生成签名的随机串
                  signature: data.str, // 必填，签名
                  jsApiList: [
                    "checkJsApi",
                    "updateAppMessageShareData",
                    "updateTimelineShareData",
                  ], // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                  //需在用户可能点击分享按钮前就先调用
                  wx.updateAppMessageShareData({
                    title: to+'-北纳生物', // 分享标题
                    desc: route.meta.content.description, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: (bncc.value as any).productPicLink || "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                    success: function () {
                      // 设置成功
                    },
                  });
                  wx.updateTimelineShareData({
                    title: to+'-北纳生物', // 分享标题
                    desc: route.meta.content.description, // 分享描述
                    link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: (bncc.value as any).productPicLink || "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg", // 分享图标
                    success: function () {
                      // 设置成功
                    },
                  });
                });
              });
            });
          }
        }
    )
    return {
      scrollLeftTo,
      bncc,
      id,
      productLotList,
      tcList,
      isFav,
      comp,
      classId,
      collectType,
      anclassId,
      tid,
      stock,
      nclassId,
      biaowuPriceOpen,
      specChecked,
      mealChecked,
      productPrice,
      clickBnccSpec,
      clickZlSpec,
      clickBacte,
      clickBnccMeal,
      clickCellMeal,
      lotid,
      tcid,
      lottype,
      lotindex,
      tcindex,
      menu,
      cellMenu,
      changeMenu,
      changeCellMenu,
      currentCellMenu,
      currentMenuItem,
      productLotMl,
      productLotnd,
      clickLotMl,
      clickLotNd,
      thirdFlag,
      ggSty,
      originalPrice,
      isLogin,
      hsProductLot1,
      hsProductLot2,
      clickLotHSNd,
      clickLotHSGe,
      dnaProductLot1,
      dnaProductLot2,
      clickLotDNANd,
      clickLotDNAGe,
      ytProductLot1,
      ytProductLot2,
      clickLotYTNd,
      clickLotYTGe,
      hsList,
      dnaList,
      ytList,
      dnaTqList,
      isApplyOpen,
      extended,
      cellscheck,
      checkvalue,
      isChecked,
      unfix,
      special


    };
  },
});
